<template>
  <b-container>
    <jumbo title="404 Not Found">
      <a href="/">Home</a>
    </jumbo>
    <the-footer></the-footer>
  </b-container>
</template>

<script>
import TheFooter from '@/web/components/TheFooter.vue';
import Jumbo from '@/web/components/Jumbo.vue';
export default {
  components: {
    TheFooter,
    Jumbo
  },
  data () {
    return {
      gifLoaded: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.gifLoaded = true;
    }, 5000);
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/custom";
</style>
